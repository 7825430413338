<template>
  <div>
    <home-header></home-header>
    <home-top></home-top>
    <slot></slot>
    <!--<home-dinein></home-dinein>-->
    <home-popular-deal></home-popular-deal>
    <home-footer></home-footer>
    <chat></chat>
    <ContactLessDelivery></ContactLessDelivery>
    <!--SECURE IMAGE PIXEL CODE START-->
    <img src="https://acuityplatform.com/Adserver/pxl/8687072593703734821?" style="float:left" width="1" height="1"/>
    <!--SECURE IMAGE PIXEL CODE END-->
  </div>
</template>
<script>

export default {
  components: {
    HomeHeader: () => import('@/components/home/HomeHeader.vue'),
    HomeTop: () => import('@/components/home/HomeTop.vue'),
    HomePopularDeal: () => import('@/components/home/HomePopularDeal.vue'),
    HomeFooter: () => import('@/components/home/HomeFooter.vue'),
    Chat: () => import('@/components/partials/Chat.vue'),
    ContactLessDelivery: () => import('@/components/popup/ContactLessDelivery')
  },
  data () {
    return {
      isShowCoupon: true
    }
  },
  mounted () {
    let body = document.body

    body.classList.remove('mainpage')
    body.classList.remove('mainpage-combo')
    body.classList.remove('combo-popup-items')
  },
  methods: {
    closeCouponPopup: function () {
      this.isShowCoupon = !this.isShowCoupon
    }
  }
}
</script>
<style>
  body {
    padding-right: 0px;
    background: none;

  }
</style>
